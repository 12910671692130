import { useMemo } from 'react';

export default function useIsIframe() {
  const isIframe = useMemo(() => {
    return typeof window !== 'undefined' && window.location !== window.parent.location;
  }, []);

  return isIframe;
}


export const withUseIsIframe = (Component) => {
  function WithUseIsIframe(props) {
    const isIframe = useIsIframe();

    return <Component {...props} isIframe={isIframe} />;
  }

  return WithUseIsIframe;
};
