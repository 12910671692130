import { useRouter as _useRouter } from 'next/router';
import useIsIframe from 'lib/hooks/useIsIframe';
import { cloneDeep, isObject, get } from 'lodash';
import qs from 'qs';


export function useRouterIframe() {
  const isIframe = useIsIframe();
  const router = _useRouter();
  const routerClone = cloneDeep(router);

  const iframePost = (operation, path) => {
    if (!operation) return;
    window.parent.postMessage({
      action: 'navigate',
      path,
      operation,
    }, '*');
  };

  const navigate = (operation) => (pathOrObj) => {
    try {
      if (isIframe) {
        // if called with object
        if (isObject(pathOrObj)) {
          const path = get(pathOrObj, 'pathname');
          const query = qs.stringify(pathOrObj.query || {});

          iframePost(operation, query !== '' ? `${path}?${query}` : path);
        } else {
          const path = pathOrObj;

          iframePost(operation, path);
        }
      }


      return router[operation || 'push'](pathOrObj);
    } catch (err) {
      console.error(`error posting to parent window: ${err.message}`, err);
    }
  };

  routerClone.push = navigate('push');
  routerClone.replace = navigate('replace');
  routerClone.back = navigate('back');

  return routerClone;
}

export const useRouter = useRouterIframe;
